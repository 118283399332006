import { orderBy } from "lodash"
import { Constraint } from "../types/constraint"
import { PaneFilter, FilterOption } from "../types/filters"
import { Recipe, RecipeFacets } from "../types/recipe"

export const sortRecipeById = (recipes: Array<Recipe>): Array<Recipe> => {
  return orderBy(recipes, ["id"], "desc")
}

export const sortRecipeByTitle = (recipes: Array<Recipe>): Array<Recipe> => {
  return orderBy(recipes, ["title"], "asc")
}

export const generatePaneFilterListFromRecipesRest = (
  remoteConstraints: Constraint[]
): Array<PaneFilter> => {
  // TODO: use data from recipes when we know what the constraints field looks like
  const ingredients: PaneFilter = {
    title: "Main ingredients",
    items: [
      {
        type: "INGREDIENT",
        value: [760, 784, 785, 820, 848, 998, 1039, 1112, 1113],
        label: "Chicken",
      },
      {
        type: "INGREDIENT",
        value: [437, 707, 712, 1052],
        label: "Chickpeas",
      },
      {
        type: "INGREDIENT",
        value: [162, 622, 1012],
        label: "Flax",
      },
      {
        type: "INGREDIENT",
        value: [120, 327, 328, 329, 330, 331, 950, 951, 973, 1009, 1062],
        label: "Onion",
      },
      {
        type: "INGREDIENT",
        value: [
          379, 380, 383, 384, 385, 386, 387, 388, 389, 390, 393, 394, 974, 975,
          1013, 1058, 1094,
        ],
        label: "Mushroom",
      },
      {
        type: "INGREDIENT",
        value: [312, 313, 314, 315, 966, 1032, 1065],
        label: "Kale",
      },
      {
        type: "INGREDIENT",
        value: [650, 651, 652, 1110],
        label: "Buckwheat",
      },
      {
        type: "INGREDIENT",
        value: [928, 929, 1120],
        label: "Scallops",
      },

      {
        type: "INGREDIENT",
        value: [
          539, 540, 541, 542, 543, 544, 545, 546, 547, 548, 549, 550, 551, 552,
          553, 788, 789, 829,
        ],
        label: "Egg",
      },
    ],
  }

  // ['pregnancy', "mental health", "diabetes", "crohns", "heart", "alzheimers", "thyroid", "vegetarian", "colitis"]
  const curatedConstraints = [17, 23, 14, 9, 3, 20, 22, 33, 4]

  const constraints: PaneFilter = {
    title: "Conditions & diets",
    items: remoteConstraints
      .filter(constraint => curatedConstraints.includes(constraint.id))
      .map(formatConstraint),
  }

  return [ingredients, constraints]
}

export const generateFilterOptionsFromRecipes = (
  facets: RecipeFacets,
  constraints: Constraint[],
  type: string
): Array<FilterOption> => {
  // currently TYPE = 'condition' || 'diet'
  // will need more use cases if more types are added

  if (!facets) return [{ label: "Loading...", value: null, variant: "loading" }]

  const constraintOptions: Array<FilterOption> =
    constraints.map(formatConstraint)

  const constraint = Object.keys(facets[type]).map(Number)
  const constraintOptionsFilteredSorted = constraintOptions
    .filter(({ value }) => {
      return [...constraint].includes(+value)
    })
    .sort((a, b) => {
      return (a.label > b.label && 1) || -1
    })

  return constraintOptionsFilteredSorted
}

const formatConstraint = (constraint: Constraint): FilterOption => {
  return {
    value: constraint.id,
    label: constraint.title
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .join(" ")
      .replace("_", "'"),
    type: "CONSTRAINT",
    variant: constraint.variant,
  }
}

export const generateCookTimeString = (cookTime: number): string => {
  const hours = Math.floor(cookTime / 60)
  const minutes = cookTime % 60
  const formatHours = hours > 0 ? hours + "h " : ""
  const formatMinute = minutes > 0 ? minutes + "m" : ""
  return formatHours + formatMinute
}
